<template>
  <div class="card card-custom mb-3">
    <div class="card-body p-5">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center mr-2">
          <div class="symbol symbol-150 symbol-light mr-3 flex-shrink-0">
            <div class="symbol-label">
              <img
                :src="
                  scenario.background
                    ? scenario.background.uri
                    : 'assets/media/bipart/lepetitjulie.png'
                "
                alt=""
                class="w-100 rounded"
              />
            </div>
          </div>
          <div class="ml-4">
            <router-link
              :to="{ name: 'scenarioDetail', params: { id: scenario.id } }"
            >
              <h3
                class="text-dark font-weight-bolder text-hover-primary">
                  {{ scenario.title }}
              </h3>
              <h5
                class="text-dark font-weight-normal">
                  {{ scenario.description | truncate(150, '...') }}
              </h5>
            </router-link>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "ScenarioCard",
  props: ["scenario", "index"],
  filters: {
    truncate(text, length, clamp){
      clamp = clamp || '...';
      var node = document.createElement('div');
      node.innerHTML = text;
      var content = node.textContent;
      return content.length > length ? content.slice(0, length) + clamp : content;
    } 
  }
};
</script>

<style scoped></style>
