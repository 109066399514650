<template>
  <div class="row h-100 justify-content-between">
    <div class="d-none d-lg-block col-lg-2 col-xl-2 bg-white">
      <div class="pl-4">
        <Phaseline
            :phases="process.processPhases"
            :processId="process.id"
            :processLogo="getProcessLogo">
        </Phaseline>
      </div>
    </div>
    <div class="col-lg-9 col-xl-9 justify-content-start">
      <div class="d-flex flex-column px-6 mt-5">
        <!--::Process Info -->
        <div class="card mb-3 card-stretch shadow-sm">
          <div class="card-header border-0 pb-4">
            <div class="row">
              <div class="col-12">
                <h1
                  class="text-dark text-hover-primary font-weight-bolder mb-1"
                >
                  {{$t("PROCESSLISTSCENARIOS.CONTESTO")}}
                </h1>
                <p class="w-75">
                  {{$t("PROCESSLISTSCENARIOS.INFO")}}
                </p>
              </div>
            </div>
          </div>
        </div>
         <!--::Tool mobile-->
        <div class="row my-2">
          <ProcessMenuMobile :process_id="id"></ProcessMenuMobile>
        </div>
      </div>
      <div class="d-flex flex-column px-6 flex-wrap">
        <div class="d-flex flex-column-fluid">
          <div v-if="tot_scenarios" class="w-100">
            <div v-for="(item, index) in process.scenarios" :key="item.id">
              <ScenarioCard :scenario="item" :index="index"></ScenarioCard>
            </div>
          </div>
          <div class="row mx-6" v-else>
            <p class="lead"> {{$t("PROCESSLISTSCENARIOS.NOSCENARI")}}</p>
        </div>
        </div>
      </div>
    </div>
    <div
      class="d-none d-lg-block col-lg-1 col-xl-1 justify-content-between  align-items-center"
    >
      <div class="d-flex flex-column mt-5 mr-5">
        <ProcessMenu :process_id="process_id"></ProcessMenu>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ScenarioCard from "@/view/pages/process/partials/ScenarioCard";
import Phaseline from "@/view/layout/common/Phaseline";
import ProcessMenu from "@/view/layout/common/ProcessMenu";
import ProcessMenuMobile from "@/view/layout/common/ProcessMenuMobile";

import { processMixin } from "@/mixins/process";

export default {
  name: "ProcessListScenarious",
  mixins: [processMixin],
  components: {
    ScenarioCard,
    Phaseline,
    ProcessMenu,
    ProcessMenuMobile
  },
  data() {
    return {
      process: [],
      process_id: this.$route.params.process_id,
      tot_scenarios: 0,
      percentage: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      //{ title: "Elenco Processi", route: "/process-list" },
      { title: this.$t("BCPROCESSI.PROCESSO"), route: "/process-detail/" + this.process_id },
      { title: this.$t("BCPROCESSI.SCENARI") }
    ]);
  },
  created() {
    this.getProcess(this.process_id).then(res => {
      this.process = res.data;
      this.percentage = this.getPercentage(this.process);
      this.tot_scenarios = this.process.scenarios.length;
    });
  }
};
</script>

<style scoped></style>
